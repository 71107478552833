/* You can add global styles to this file, and also import other style files */
/* Color Variables */
/* Color Variable */
/* Font Variable */
body {
  color: #717477;
  font-family: "Poppins", sans-serif;
  line-height: 1.8;
  font-size: 16px;
}

/* Default CSS Start */
img {
  max-width: 100%;
}

a {
  transition: 0.5s;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

button {
  outline: 0 !important;
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.form-control:focus {
  box-shadow: 0 0 0 0;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  color: #333333;
}

h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  color: #0f2137;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

.theme-button {
  text-align: center;
  z-index: 1;
  position: relative;
}
.theme-button .default-btn {
  width: 200px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: transparent;
  padding: 16px 40px;
  position: relative;
  display: inline-block;
  border: 2px solid white;
}
.theme-button .default-btn:hover {
  background-color: white;
  color: #008dd2;
}

.section-title {
  margin: 0 auto 50px;
  max-width: 600px;
}
.section-title span {
  font-size: 16px;
  color: #886a03;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.section-title h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.form-group button {
  padding: 6px 14px 7px 14px;
  text-align: left;
  background: #fff;
  font-size: 15px;
}

.form-group button a {
  color: white;
}

.banner-image {
  background-color: #008ed2 !important;
  width: 350px;
  right: 90px;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 9;
  overflow: hidden;
}

.row .col {
  background: #fff;
}

.banner-image h4 {
  text-align: center;
  font-size: 14px;
  color: #fff;
  padding: 10px 10px 0;
  line-height: 24px;
  margin: 0 auto 10px;
}

.banner-image h3 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.theory-text {
  position: relative;
}

.theory-text col {
  animation: translatey 8s infinite linear;
  position: absolute;
}

.slick-slider {
  width: 88%;
  margin: auto;
  background: rgb(14, 13, 13);
}

body .slick-prev,
body .slick-next {
  height: 35px;
  width: 35px;
  background-color: #008ed2 !important;
  z-index: 100;
  border-radius: 50%;
}

.slick-slider {
  width: 88%;
  margin: auto;
  background: transparent;
}

body .slick-prev,
body .slick-next {
  height: 35px;
  width: 35px;
  background-color: #008ed2 !important;
  z-index: 100;
  border-radius: 50%;
}

.slide {
  position: relative;
}

.slide .slide-about {
  position: absolute;
  right: 20px;
  width: 40%;
}

.slide img {
  width: 80%;
  margin-left: 32px;
}

.slide-about h1 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 15px;
}

.service-text p {
  color: #daefff;
}

.features-card {
  height: 400px;
}

/* Default CSS End */
/** -------------- Home Page One CSS Start --------------**/
/* Header CSS Start */
.header-area {
  background: #008ed2;
  padding: 5px 0;
}
.header-area .header-left-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.header-area .header-left-text ul li {
  display: inline-block;
  color: #565659;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-right: 20px;
  line-height: 1;
}
.header-area .header-left-text ul li a {
  color: #fff;
}
.header-area .header-left-text ul li i {
  margin-right: 5px;
  font-size: 16px;
  top: 1px;
  position: relative;
}
.header-area .header-right .header-social ul {
  padding-left: 0;
  margin-bottom: 0;
}
.header-area .header-right .header-social ul li {
  display: inline-block;
  line-height: 1;
  margin-left: 5px;
}
.header-area .header-right .header-social ul li a i {
  width: 25px;
  height: 25px;
  line-height: 23px;
  background: #008dd2;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  transition: 0.5s;
  border: 1px solid transparent;
}
.header-area .header-right .header-social ul li a i:hover {
  background: #FFFFFF;
  color: #008dd2;
}

/* Header CSS End */
/* Navbar CSS Start */
.navbar-area .main-nav {
  position: inherit;
  background: #FFFFFF;
  border-bottom: 2px solid #fff9e6;
}
.navbar-area .main-nav .navbar {
  padding: 0;
}
.navbar-area .main-nav .navbar .navbar-brand {
  padding: 0;
  top: -2px;
  position: relative;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .nav-link {
  color: #4a4a4a;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  font-size: 13px;
  letter-spacing: 0.5px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .nav-link > i {
  font-size: 12px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .nav-link.active {
  color: #008dd2 !important;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .Services {
  width: 1340px;
  background: #FFFFFF;
  padding: 10px 0;
  border: 0;
  transform: scaleX(0);
  top: 55px !important;
  left: -180px;
  overflow: hidden;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  background: #FFFFFF;
  padding: 10px 0;
  border: 0;
  transform: scaleX(0);
  top: 55px !important;
  right: 0;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
  border-bottom: 1px dashed #008dd2;
  padding: 10px 15px;
  position: relative;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link > i {
  font-size: 12px;
  float: right;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover {
  color: #008dd2;
  padding-left: 25px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover::before {
  width: 10px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover::after {
  width: 0px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link::before {
  position: absolute;
  content: "";
  background: #008dd2;
  width: 0;
  height: 1px;
  top: 50%;
  left: 0;
  right: 0;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link.active {
  color: #008dd2;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover {
  color: #008dd2;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
  left: -100%;
  top: 0 !important;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu .nav-item .nav-link:hover {
  color: #008dd2;
  padding-left: 25px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu .nav-item .nav-link:hover::before {
  width: 10px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu .nav-item .nav-link:hover::after {
  width: 0px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu .nav-item .nav-link::before {
  position: absolute;
  content: "";
  background: #008dd2;
  width: 0;
  height: 1px;
  top: 50%;
  left: 0;
  right: 0;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu .nav-item .nav-link.active {
  color: #008dd2;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible;
  top: 0px !important;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu:last-child .nav-link {
  border-bottom: 0px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-toggle::after {
  display: none;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  transform: scaleX(1);
}
.navbar-area .main-nav .navbar .other-option {
  display: flex;
}
.navbar-area .main-nav .navbar .other-option .search-bar {
  position: relative;
}
.navbar-area .main-nav .navbar .other-option .search-bar .search-icon {
  width: 30px;
  height: 30px;
  border: 1px solid #008dd2;
  text-align: center;
  line-height: 27px;
  color: #008dd2;
  display: inline-block;
  cursor: pointer;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar .other-option .search-bar .search-icon:hover {
  background: #008dd2;
  color: #FFFFFF;
  border-radius: 20px;
}
.navbar-area .main-nav .navbar .other-option .search-bar .search-form {
  position: absolute;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  padding: 15px;
  background: #FFFFFF;
  top: 70px;
  animation: fadeInDown 1s;
  display: none;
  z-index: 1;
}
.navbar-area .main-nav .navbar .other-option .search-bar .search-form .search-input {
  height: 50px;
  width: 230px;
  padding-left: 20px;
  border: 1px dashed #008dd2;
  font-size: 20px;
  background: #FFFFFF;
}
.navbar-area .main-nav .navbar .other-option .search-bar .search-form .search-input:focus {
  border: 1px solid #008dd2;
  outline: 0;
}
.navbar-area .main-nav .navbar .other-option .search-bar .search-form ::placeholder {
  color: #717477;
  font-size: 18px;
}
.navbar-area .main-nav .navbar .other-option .search-bar .search-form button {
  position: absolute;
  top: 23px;
  right: 25px;
  border: 1px solid #008dd2;
  border-radius: 17px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  color: #008dd2;
  width: 33px;
  height: 33px;
  line-height: 30px;
}
.navbar-area .main-nav .navbar .other-option .sidebar-toggle .navbar-toggle {
  width: 30px;
  height: 30px;
  border: 1px solid #008dd2;
  text-align: center;
  line-height: 27px;
  color: #008dd2;
  display: inline-block;
  cursor: pointer;
  transition: 0.5s;
  padding: 0;
  border-radius: 0;
  margin-left: 10px;
}
.navbar-area .main-nav .navbar .other-option .sidebar-toggle .navbar-toggle:hover {
  background: #008dd2;
  color: #FFFFFF;
}
.navbar-area .main-nav .navbar .other-option .sidebar-toggle .navbar-toggle:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}
.navbar-area.sticky-top {
  animation: 0.5s ease-in-out fadeInDown;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

/* Navbar CSS End */
/* Modal CSS Start */
.modal.fade:not(.in).left .modal-dialog {
  transform: translate3d(-25%, 0, 0);
}

.modal.fade:not(.in).right .modal-dialog {
  transform: translate3d(0%, 0, 0);
}

.modal.fade:not(.in).bottom .modal-dialog {
  transform: translate3d(0, 25%, 0);
}

.modal.right .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.right-modal .modal {
  z-index: 9999999;
  transition: 1s;
}
.right-modal .modal .modal-header img {
  width: 90px;
  padding-top: 5px;
  padding-left: 20px;
}
.right-modal .modal .modal-header button span {
  background: #FFFFFF;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 40px;
  line-height: 50px;
  transition: 0.5s;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.right-modal .modal .modal-header button span:hover {
  border-radius: 0px;
}
.right-modal .modal .modal-body {
  width: 500px;
  padding: 40px;
  background: #FFFFFF;
}
.right-modal .modal .modal-body .modal-link {
  position: relative;
  margin-bottom: 30px;
}
.right-modal .modal .modal-body .modal-link a {
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.right-modal .modal .modal-body .modal-link h3 a {
  position: absolute;
  bottom: -8px;
  left: 0;
  background: #FFFFFF;
  width: 100%;
  height: 50px;
  text-align: center;
  padding-top: 15px;
  color: #3f3f3f;
  display: inline-block;
  font-size: 18px;
}
.right-modal .modal .modal-body .modal-link:hover h3 a {
  background: #008dd2;
  color: #FFFFFF;
}
.right-modal .modal.fade {
  transition: 0.5s linear;
  transform: translateX(100%);
}
.right-modal .modal.show {
  transform: translateX(0%);
}

.modal.right .modal-content {
  min-height: 100vh;
  border: 0;
}

.modal-body {
  padding: 0;
}

.modal-header .close {
  margin-right: 0;
}

/* Modal CSS End */
/* Banner CSS Start */
.main-banner {
  position: relative;
  height: 500px;
}
.main-banner .banner-text h1 {
  font-size: 50px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-bottom: 30px;
  margin-top: 60px;
  text-align: center;
}
.main-banner .banner-text p {
  color: #808291;
  margin-bottom: 40px;
}
.main-banner .banner-text .theme-button .default-btn {
  margin-right: 30px;
}
.main-banner .banner-text .theme-button .video-btn {
  color: #333333;
  font-weight: 500;
  top: 6px;
  position: relative;
}
.main-banner .banner-text .theme-button .video-btn:hover {
  color: #008dd2;
}
.main-banner .banner-text .theme-button .video-btn i {
  color: #008dd2;
  border: 1px solid #008dd2;
  width: 60px;
  height: 60px;
  display: inline-block;
  text-align: center;
  line-height: 60px;
  transition: 0.5s;
  margin-right: 10px;
  font-size: 25px;
  position: relative;
}
.main-banner .banner-text .theme-button .video-btn:hover i {
  border-radius: 50px;
  box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.15);
}
.main-banner .banner-image {
  position: absolute;
  right: 100px;
  top: 100px;
  max-width: 450px;
  animation: translatey 8s infinite linear;
}

/* Banner CSS End */
/* Video CSS Start */
.video-section {
  display: none !important;
  background: #008ed2;
  position: relative;
  padding-bottom: 200px;
}
.video-section::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/video-shape.png);
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.video-section .video-text {
  position: relative;
}
.video-section .video-text i {
  color: #FFFFFF;
  background: #008dd2;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 85px;
  display: inline-block;
  font-size: 60px;
  position: relative;
  z-index: 0;
}
.video-section .video-text i::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid rgba(255, 101, 80, 0.5);
  animation: pulse 2s ease-out infinite;
  left: 0;
  top: 0;
}
.video-section .video-text p {
  position: absolute;
  left: 150px;
  top: 0;
  font-size: 30px;
  color: #FFFFFF;
  line-height: 1.4;
}

/* Video CSS End */
/* Theory CSS Start */
.theory-section {
  margin-top: -50px;
}
.theory-section .theory-area {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.04);
}
.theory-section .theory-area .theory-text {
  padding: 60px;
  background-size: cover;
  background-position: center;
}
.theory-section .theory-area .theory-text h2 {
  font-size: 35px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 25px;
  line-height: 1.4;
}
.theory-section .theory-area .theory-text p {
  color: #333333;
  margin-bottom: 30px;
}
.theory-section .theory-area .theory-text .signature-box img {
  margin-bottom: 10px;
}
.theory-section .theory-area .theory-text .signature-box p {
  margin-bottom: 0;
  color: #333333;
  font-weight: 500;
}
.theory-section .theory-area .theory-text .signature-box span {
  font-size: 14px;
  color: #333333;
}
.theory-section .theory-area .theory-img {
  position: absolute;
  right: 0;
  width: 45%;
  bottom: 108px;
}

/* Theory CSS End */
.port-folio {
  padding: 50px;
  border-radius: 20px;
  box-shadow: 1px 1px #008ed2;
  margin-top: 50px;
}
.port-folio h2 {
  font-weight: bold;
}
.port-folio p, .port-folio ul {
  text-align: justify;
}

.about-image-port img {
  width: 70%;
}

/* About CSS Start */
.about-section {
  padding-top: 30px;
  background: #FFFFFF;
}
.about-section .about-image img {
  z-index: 1;
  width: 100%;
  max-width: unset;
}
.about-section .about-text .section-title {
  margin: 0 auto 45px 0;
}
.about-section .about-text .about-us {
  margin-bottom: 20px;
}
.about-section .about-text .about-us i {
  font-size: 40px;
  left: 20px;
  position: relative;
  top: 0;
  line-height: 1;
  margin-bottom: 10px;
  display: inline-block;
  color: #008ed2;
  text-align: center;
  z-index: 0;
}
.about-section .about-text .about-us i::after {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  top: 0;
  left: -15px;
  z-index: -1;
}
.about-section .about-text .about-us i.icon-one::after {
  background: rgba(132, 186, 63, 0.65);
}
.about-section .about-text .about-us i.icon-two::after {
  background: rgba(255, 124, 0, 0.64);
  border-radius: 50px;
}
.about-section .about-text .about-us i.icon-three::after {
  background: rgba(255, 199, 10, 0.66);
  transform: rotate(30deg);
}
.about-section .about-text .about-us p {
  font-size: 14px;
  color: #808291;
  margin-bottom: 0;
  text-align: left;
}
.about-section .about-text p {
  color: #808291;
  margin-bottom: 45px;
  text-align: justify;
}

/* About CSS End */
/* Feature CSS Start */
.features-section .features-card {
  text-align: center;
  box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.1);
  padding: 30px 20px;
  margin-bottom: 30px;
  transition: 0.5s;
  position: relative;
  background: #FFFFFF;
  z-index: 1;
}
.features-section .features-card:hover {
  margin-top: -10px;
  box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.04);
}
.features-section .features-card:hover p, .features-section .features-card:hover h3 {
  color: #FFFFFF;
}
.features-section .features-card i {
  font-size: 35px;
  width: 90px;
  height: 90px;
  display: inline-block;
  text-align: center;
  line-height: 90px;
  border-radius: 46% 54% 68% 32%/55% 48% 52% 45%;
  margin-bottom: 25px;
  animation: icon-animation 5s ease-in-out infinite;
  border: 1px dashed;
}
.features-section .features-card h3 {
  font-weight: 600;
  margin-bottom: 10px;
  transition: 0.5s;
}
.features-section .features-card p {
  margin-bottom: 0;
  font-size: 16px;
  transition: 0.5s;
}
.features-section .features-card.feature-one i {
  color: #18a87c;
  background: #daf4ec;
}
.features-section .features-card.feature-one:hover {
  background: #008ed2;
}
.features-section .features-card.feature-two i {
  color: #f0573c;
  background: #ffd7ca;
}
.features-section .features-card.feature-two:hover {
  background: #008ed2;
}
.features-section .features-card.feature-three i {
  color: #3454d1;
  background: #e1e6f8;
}
.features-section .features-card.feature-three:hover {
  background: #008ed2;
}
.features-section .features-card.feature-four i {
  color: #ffc70b;
  background: #fff9e6;
}
.features-section .features-card.feature-four:hover {
  background: #008ed2;
}
.features-section .features-card.feature-five i {
  color: #f26d56;
  background: #ede5fb;
}
.features-section .features-card.feature-five:hover {
  background: #008ed2;
}
.features-section .features-card.feature-six i {
  color: #88a32a;
  background: #eff2e2;
}
.features-section .features-card.feature-six:hover {
  background: #008ed2;
}

/* Feature CSS End */
/* Service CSS Start */
.service-section {
  position: relative;
}
.service-section .service-img {
  position: absolute;
  background-position: center center;
  background-size: cover;
  width: 55%;
  height: 100%;
  left: 0;
  top: 0;
}
.service-section .service-text {
  position: relative;
  background: #008ed2;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 100px;
}
.service-section .service-text h2 {
  margin-bottom: 50px;
  color: #FFFFFF;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
}
.service-section .service-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.service-section .service-text ul li {
  padding: 15px 15px 15px 90px;
  background: #FFFFFF;
  margin-bottom: 20px;
  position: relative;
  color: #808291;
  font-size: 18px;
  font-weight: 500;
  max-width: 400px;
  list-style: none;
}
.service-section .service-text ul li i {
  background: #008dd2;
  color: #FFFFFF;
  text-align: center;
  position: absolute;
  line-height: 65px;
  font-size: 25px;
  height: 100%;
  width: 60px;
  left: 0;
  top: 0;
}

/* Service CSS End */
/* Team CSS End */
.team-section .section-title {
  max-width: 700px;
}
.team-section .team-card {
  margin-bottom: 80px;
}
.team-section .team-card:hover .team-text {
  background: #008dd2;
}
.team-section .team-card:hover .team-text h3 {
  color: #FFFFFF;
}
.team-section .team-card:hover .team-text p {
  color: #FFFFFF;
}
.team-section .team-card:hover .team-text .team-social {
  opacity: 1;
  transform: translateY(-15px);
}
.team-section .team-card .team-img {
  position: relative;
}
.team-section .team-card .team-text {
  background: #FFFFFF;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(12, 0, 46, 0.15);
  position: absolute;
  text-align: center;
  z-index: 1;
  width: 230px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -50px;
  transition: 0.5s;
  transition: 0.5s;
}
.team-section .team-card .team-text .team-social {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  transition: 0.5s;
}
.team-section .team-card .team-text .team-social ul {
  padding-left: 0;
  margin-bottom: 0;
}
.team-section .team-card .team-text .team-social ul li {
  display: inline-block;
}
.team-section .team-card .team-text .team-social ul li i {
  background: #FFFFFF;
  color: #333333;
  width: 25px;
  height: 25px;
  display: inline-block;
  line-height: 25px;
  text-align: center;
  transition: 0.5s;
  font-size: 16px;
}
.team-section .team-card .team-text .team-social ul li i:hover {
  color: #008dd2;
}
.team-section .team-card .team-text h3 {
  font-size: 18px;
  font-weight: 700;
  transition: 0.5s;
}
.team-section .team-card .team-text p {
  margin-bottom: 0;
  transition: 0.5s;
}

/* Team CSS End */
/* Testimonial CSS Start */
.testimonial-section {
  background: #008ed2;
}
.testimonial-section .testimonial-slider {
  text-align: center;
  position: relative;
  z-index: 0;
}
.testimonial-section .testimonial-slider::before {
  position: absolute;
  content: "";
  background: #FFFFFF;
  width: 85%;
  height: 85%;
  left: 0;
  z-index: -1;
  margin: auto;
  right: 0;
  bottom: -20px;
  z-index: -1;
  border-radius: 30px;
}
.testimonial-section .testimonial-slider img {
  max-width: 150px;
  width: unset;
  margin: 0 auto 40px;
  right: 0;
  left: 0;
  border: 3px solid #008dd2;
  border-radius: 150px;
  padding: 5px;
  background: #FFFFFF;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
}
.testimonial-section .testimonial-slider h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}
.testimonial-section .testimonial-slider span {
  color: #008dd2;
  font-size: 16px;
  margin-bottom: 20px;
  display: inline-block;
}
.testimonial-section .testimonial-slider p {
  padding: 0 105px;
}
.testimonial-section .testimonial-slider .owl-prev:hover, .testimonial-section .testimonial-slider .owl-next:hover {
  background: none;
}
.testimonial-section .testimonial-slider .owl-prev span, .testimonial-section .testimonial-slider .owl-next span {
  color: #008dd2;
  font-size: 16px;
  margin-bottom: 20px;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  line-height: 40px;
  font-size: 30px;
  text-align: center;
  border: 1px solid #008dd2 !important;
  transition: 0.5s;
}
.testimonial-section .testimonial-slider .owl-prev span:hover, .testimonial-section .testimonial-slider .owl-next span:hover {
  background: #008dd2;
  color: #FFFFFF !important;
}
.testimonial-section .testimonial-image {
  background-position: center center;
  background-size: cover;
  height: 100%;
}

/* Testimonial CSS End */
/* Portfolio CSS Start */
.portfolio-section .portfolio-item {
  cursor: pointer;
  margin-bottom: 30px;
}
.portfolio-section .portfolio-item:hover .portfolio-img .portfolio-text {
  bottom: 0;
}
.portfolio-section .portfolio-item .portfolio-img {
  position: relative;
  overflow: hidden;
}
.portfolio-section .portfolio-item .portfolio-img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}
.portfolio-section .portfolio-item .portfolio-img img {
  width: 100%;
  height: 300px;
}
.portfolio-section .portfolio-item .portfolio-img .portfolio-text {
  position: absolute;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  transition: 0.5s;
  background: rgba(0, 0, 0, 0.5);
}
.portfolio-section .portfolio-item .portfolio-img .portfolio-text h3 {
  color: #FFFFFF;
  font-weight: 700;
  padding: 15px 0 15px;
  margin-bottom: 0;
}
.portfolio-section .portfolio-item .portfolio-img .portfolio-text .portfolio-btn {
  font-size: 14px;
  font-weight: 500;
  background: #008dd2;
  color: #FFFFFF;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.portfolio-section .portfolio-item .portfolio-img .portfolio-text .portfolio-btn i {
  font-size: 10px;
}
.portfolio-section .portfolio-item .portfolio-img .portfolio-text .portfolio-btn:hover {
  border: 1px solid #FFFFFF;
  background: transparent;
}

/* Portfolio CSS End */
/* Progress CSS Start */
.progress-section {
  position: relative;
}
.progress-section .progress-image {
  background-image: url(assets/img/mee.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 60%;
  left: 0;
  top: 0;
}
.progress-section .progress-text {
  position: relative;
  background: url("/assets/img/why.webp");
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 80px;
}
.progress-section .progress-text h2 {
  color: #FFFFFF;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 35px;
  line-height: 1.4;
}
.progress-section .progress-text p {
  color: #FFFFFF;
  margin-bottom: 80px;
}
.progress-section .progress-text .progress-inner {
  position: relative;
  margin-bottom: 60px;
}
.progress-section .progress-text .progress-inner p {
  position: absolute;
  margin-bottom: 0;
  top: -35px;
  color: #FFFFFF;
}
.progress-section .progress-text .progress-inner span {
  position: absolute;
  top: -35px;
  right: 0;
  color: #FFFFFF;
}
.progress-section .progress-text .progress-inner .progress {
  height: 6px;
  border-radius: 0;
}
.progress-section .progress-text .progress-inner .progress .progress-bar {
  background-color: #008dd2;
  height: 6px;
}
.progress-section .progress-text :last-child {
  margin-bottom: 0;
}

/* Progress CSS End */
/* Blog CSS Start */
.blog-section .blog-card {
  box-shadow: 0px 2px 25px 0px rgba(12, 0, 46, 0.1);
  margin-bottom: 30px;
  background: #FFFFFF;
  height: 370px;
}
.blog-section .blog-card:hover .blog-img img {
  transform: scale(1.1);
}
.blog-section .blog-card:hover .blog-img .blog-date {
  left: 0;
}
.blog-section .blog-card .blog-img {
  height: 200px;
  position: relative;
  overflow: hidden;
}
.blog-section .blog-card .blog-img img {
  transition: 0.5s;
}
.blog-section .blog-card .blog-img .blog-date {
  position: absolute;
  background: #008dd2;
  width: 55px;
  text-align: center;
  top: 0;
  left: 25px;
  padding: 6px;
  transition: 0.5s;
}
.blog-section .blog-card .blog-img .blog-date span {
  color: #FFFFFF;
  display: inline-block;
  line-height: 25px;
}
.blog-section .blog-card .blog-text {
  padding: 25px 25px 65px;
}
.blog-section .blog-card .blog-text h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  transition: 0.5s;
  line-height: 1.4;
}
.blog-section .blog-card .blog-text h3 a {
  color: #111111;
}
.blog-section .blog-card .blog-text .post-info {
  position: relative;
}
.blog-section .blog-card .blog-text .post-info img {
  position: absolute;
}
.blog-section .blog-card .blog-text .post-info a p {
  position: absolute;
  left: 50px;
  top: 8px;
  font-size: 14px;
  color: #808291;
  margin-bottom: 0;
  transition: 0.5s;
}
.blog-section .blog-card .blog-text .post-info .blog-btn {
  position: absolute;
  right: 0;
  top: 9px;
  font-size: 14px;
  color: #808291;
}
.blog-section .blog-card .blog-text .post-info .blog-btn i {
  font-size: 12px;
}
.blog-section .blog-card:hover .blog-text a h3, .blog-section .blog-card:hover .blog-text .post-info a p, .blog-section .blog-card:hover .blog-text .post-info .blog-btn {
  color: #008dd2;
}

/* Blog CSS End */
/* Contact CSS Start */
.contact-section .contact-img {
  position: relative;
  background-position: center right;
  background-size: cover;
}
.contact-section .contact-img img {
  position: absolute;
  max-width: 100%;
  height: 100%;
  display: none;
}
.contact-section .contact-form {
  box-shadow: 0px 2px 25px 0px rgba(12, 0, 46, 0.1);
  padding: 70px;
  background: #FFFFFF;
  position: relative;
}
.contact-section .contact-form .contact-text {
  padding-bottom: 40px;
}
.contact-section .contact-form .contact-text h3 {
  font-weight: 600;
  margin-bottom: 15px;
}
.contact-section .contact-form .contact-text p {
  color: #646464;
  margin-bottom: 0;
}
.contact-section .contact-form .form-group .form-control {
  height: 50px;
  border: 1px solid #008dd2;
  border-radius: 10px;
}
.contact-section .contact-form .form-group .message-field {
  border: 1px solid #008dd2;
  width: 100%;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 10px;
}
.contact-section .contact-form .form-group .message-field:focus {
  border: 1px solid #008dd2;
  outline: 0;
}
.contact-section .contact-form .form-group .message-field .list-unstyled {
  margin-top: 0;
}
.contact-section .contact-form .form-group ::placeholder {
  color: #464646;
}
.contact-section .contact-form .default-btn {
  color: #FFFFFF;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 10px;
  z-index: 0;
  position: relative;
  transition: 0.5s;
  font-size: 16px;
  border: 1px solid #008dd2;
  margin-bottom: 10px;
}
.contact-section .contact-form .default-btn::before {
  position: absolute;
  content: "";
  background: #008dd2;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  top: 0;
  left: 0;
  transform: scale(1);
  transition: 0.5s;
  z-index: -1;
}
.contact-section .contact-form .default-btn:hover::before {
  transform: scale(0);
}
.contact-section .contact-form .default-btn:hover {
  color: #008dd2;
  border: 1px solid #008dd2;
}
.contact-section .contact-form .contact-shape {
  position: absolute;
  right: 0;
  bottom: 0;
  animation: translatey 5s infinite linear;
}

/* Contact CSS End */
/* Newsletter CSS Start */
.newsletter-section .newsletter-area {
  background: #008dd2;
  padding: 70px;
  margin-bottom: -140px;
  z-index: 1;
  position: relative;
  text-align: center;
}
.newsletter-section .newsletter-area h2 {
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 35px;
  line-height: 1;
}
.newsletter-section .newsletter-area .newsletter-form {
  position: relative;
}
.newsletter-section .newsletter-area .newsletter-form .form-control {
  border: 1px solid #FFFFFF;
  padding: 20px 0 20px 20px;
  height: 50px;
  background: #008dd2 !important;
  color: #FFFFFF;
  border-radius: 0;
}
.newsletter-section .newsletter-area .newsletter-form ::placeholder {
  color: #FFFFFF;
}
.newsletter-section .newsletter-area .newsletter-form .default-btn {
  position: absolute;
  font-size: 16px;
  color: #FFFFFF;
  height: 50px;
  padding: 0 20px;
  top: 0px;
  right: -1px;
  transition: 0.5s;
  border-left: 1px solid;
  background: #008dd2;
  border: 1px solid;
}
.newsletter-section .newsletter-area .newsletter-form .default-btn:hover {
  color: #FFFFFF;
  background: #000;
}
.newsletter-section .newsletter-area .subscribe-shape {
  position: absolute;
  right: 50px;
  bottom: 0;
  animation: translatey 5s infinite linear;
}

/* Newsletter CSS End */
/* Footer Section CSS Start */
.footer-area {
  background: #008ed2;
  padding-top: 235px;
  clip-path: polygon(53% 15%, 100% 0, 100% 43%, 100% 78%, 100% 100%, 32% 100%, 0 100%, 0% 43%, 0 0);
}
.footer-area .footer-widget .footer-logo img {
  margin-bottom: 25px;
}
.footer-area .footer-widget p {
  color: #FFFFFF;
  margin-bottom: 18px;
}
.footer-area .footer-widget .footer-social a {
  display: inline-block;
  margin-right: 5px;
}
.footer-area .footer-widget .footer-social a i {
  color: #FFFFFF;
  font-size: 16px;
  margin-right: 10px;
  transition: 0.5s;
}
.footer-area .footer-widget h3 {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  display: inline-block;
  position: relative;
  padding-bottom: 5px;
}
.footer-area .footer-widget h3::before {
  position: absolute;
  content: "";
  background: #626a5b;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
}
.footer-area .footer-widget ul {
  padding-left: 0;
  line-height: 1;
  margin-bottom: 0;
}
.footer-area .footer-widget ul li {
  display: block;
  margin-bottom: 20px;
  transition: 0.5s;
  color: #FFFFFF;
}
.footer-area .footer-widget ul li a {
  color: #FFFFFF;
  transition: 0.5s;
}
.footer-area .footer-widget ul li a:hover {
  color: white;
  margin-left: 2px;
}
.footer-area .footer-widget ul li a:hover i {
  color: white;
}
.footer-area .footer-widget ul li a i {
  font-size: 12px;
  top: -1px;
  position: relative;
}
.footer-area .footer-widget ul li i {
  font-size: 12px;
  transition: 0.5s;
}
.footer-area .footer-widget ul :last-child {
  margin-bottom: 0;
}
.footer-area .pl-75 {
  padding-left: 75px;
}
.footer-area .copyright-area {
  border-top: 1px solid #4a6871;
  padding: 20px 0;
  margin-top: 95px;
}
.footer-area .copyright-area p {
  color: #FFFFFF;
  margin-bottom: 0;
  text-align: left;
}
.footer-area .copyright-area p a {
  color: #FFFFFF;
}
.footer-area .copyright-area p a:hover {
  color: #008dd2;
}
.footer-area .copyright-area .lh-1 {
  line-height: 1;
}
.footer-area .copyright-area ul {
  margin-bottom: 0;
}
.footer-area .copyright-area ul li {
  display: inline-block;
  margin-left: 25px;
  position: relative;
}
.footer-area .copyright-area ul li::before {
  position: absolute;
  content: "";
  background: #FFFFFF;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  right: -15px;
  top: 6px;
}
.footer-area .copyright-area ul li a {
  color: #FFFFFF;
}
.footer-area .copyright-area ul li a:hover {
  color: #008dd2;
}
.footer-area .copyright-area ul :nth-child(2)::before {
  display: none;
}

/* Footer Section CSS End */
/** -------------- Home Page One CSS End --------------**/
/** -------------- Home Page Two CSS Start --------------**/
/* Header CSS Start */
.header-style-two {
  background: #071d24;
}
.header-style-two .header-left-text ul li {
  color: #95999a;
}
.header-style-two .header-left-text ul li a {
  color: #95999a;
}

/* Header CSS End */
/* Navbar CSS Start */
.navbar-style-two .main-nav {
  background: white;
}
.navbar-style-two .main-nav .navbar .navbar-nav .nav-item .nav-link {
  color: #0e0303;
}
.navbar-style-two .main-nav .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #646464;
}
.navbar-style-two .main-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  background: #008ed2;
  box-shadow: 3px -5px 12px rgba(0, 0, 0, 0.5);
}
.navbar-style-two .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
  color: #9d9eaa;
}
.navbar-style-two .main-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover {
  color: #008dd2;
}

/* Navbar CSS End */
/* Banner CSS Start */
.banner-style-two {
  background: #008ed2;
}
.banner-style-two .banner-text h1 {
  color: #FFFFFF;
}
.banner-style-two .banner-text p {
  color: #FFFFFF;
}
.banner-style-two .banner-text .default-btn::before {
  background: transparent;
  border: 1px solid #008dd2;
}
.banner-style-two .banner-text .video-btn {
  color: #008dd2 !important;
}
.banner-style-two .banner-text .video-btn:hover {
  color: #FFFFFF !important;
}
.banner-style-two .banner-text .video-btn:hover i {
  background: #FFFFFF;
}
.banner-style-two .banner-image {
  position: absolute;
  top: 100px;
}

/* Banner CSS End */
.about-image1 {
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

/* About CSS Start */
.about-style-two {
  padding: 50px 0;
  position: relative;
}
.about-style-two .about-image {
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.about-style-two .about-text {
  position: relative;
  background: #008ed2;
  padding: 70px 80px;
}
.about-style-two .about-text p {
  color: #FFFFFF;
}
.about-style-two .about-text .section-title span {
  color: #886a03;
}
.about-style-two .about-text .section-title h2 {
  color: #FFFFFF;
}
.about-style-two .about-text .about-us p {
  color: #FFFFFF;
}

/* About CSS End */
/* Video CSS Start */
.video-style-two {
  background-image: url(assets/img/Watch-Our-Video.jpeg);
  background-position: center center;
  background-size: cover;
  position: relative;
  height: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  z-index: 0;
}
.video-style-two::before {
  position: absolute;
  content: "";
  background: #464544;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.45;
  z-index: -1;
}
.video-style-two .video-text a {
  margin-bottom: 38px;
  display: inline-block;
}
.video-style-two .video-text a i {
  color: #FFFFFF;
  background: #008dd2;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 85px;
  display: inline-block;
  font-size: 50px;
  position: relative;
  z-index: 0;
  border-radius: 55% 45% 38% 62%/52% 57% 43% 48%;
}
.video-style-two .video-text a i::after {
  position: absolute;
  content: "";
  background: rgba(255, 101, 80, 0.5);
  width: 100px;
  height: 100px;
  top: -10px;
  left: -10px;
  z-index: -1;
  border-radius: 55% 45% 38% 62%/52% 57% 43% 48%;
  animation: wrep 5s infinite ease-in-out;
}
.video-style-two .video-text p {
  color: #FFFFFF;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1;
}

/* Video CSS End */
/* Service CSS Start */
.service-style-two .service-img {
  width: 50%;
}
.service-style-two .service-text {
  margin-top: 0;
  margin-bottom: 0;
}

/* Service CSS End */
/* Progress CSS Start */
.progress-style-two .progress-image {
  width: 50%;
  right: 0;
  left: inherit;
}
.progress-style-two .progress-text {
  margin-top: 0;
  margin-bottom: 0;
}

/* Progress CSS End */
/* Price CSS Start */
.pricing-section .pricing-card {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: relative;
  margin-bottom: 30px;
  background: #FFFFFF;
}
.pricing-section .pricing-card::before {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  content: "";
  height: 160px;
  position: absolute;
  background: #008dd2;
  clip-path: polygon(0 0, 100% 0, 100% 65%, 0% 100%);
}
.pricing-section .pricing-card .price-header {
  position: relative;
  margin-bottom: 80px;
}
.pricing-section .pricing-card .price-header h2 {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 1;
}
.pricing-section .pricing-card .price-header h3 {
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 700;
}
.pricing-section .pricing-card .price-header h3 sup {
  font-size: 15px;
  font-weight: 400;
  top: -14px;
  position: relative;
}
.pricing-section .pricing-card .price-header h3 span {
  font-size: 15px;
  font-weight: 400;
}
.pricing-section .pricing-card .price-header i {
  left: 0;
  right: 0;
  top: 75px;
  z-index: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  color: #000000;
  font-size: 60px;
  line-height: 90px;
  position: absolute;
  border-radius: 50px;
  display: inline-block;
  background: #FFFFFF;
  border: 5px solid #008dd2;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}
.pricing-section .pricing-card .pricing-feature {
  padding: 45px 0px 10px;
}
.pricing-section .pricing-card .pricing-feature ul {
  padding-left: 0;
  margin-bottom: 0;
}
.pricing-section .pricing-card .pricing-feature ul li {
  color: #808291;
  font-size: 14px;
  margin-bottom: 0px;
  list-style: none;
}
.pricing-section .pricing-card .pricing-feature ul li i {
  color: #008dd2;
  margin-right: 10px;
}
.pricing-section .pricing-card .pricing-feature ul :last-child {
  margin-bottom: 0;
}
.pricing-section .pricing-card.price-card-two i {
  z-index: 1;
}
.pricing-section .pricing-card.price-card-two::before {
  height: 170px;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
}
.pricing-section .pricing-card.price-card-two::after {
  position: absolute;
  content: "";
  width: 50px;
  top: 95px;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 90px solid transparent;
  border-right: 90px solid transparent;
  border-top: 100px solid #008dd2;
  z-index: 0;
}
.pricing-section .pricing-card.price-card-three::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 65%);
}

.pricing-section .default-btn {
  padding: 3px 10px !important;
}

/* Price CSS End */
/**-------------- Home Page Two CSS End --------------**/
/**-------------- About Page CSS Start --------------**/
/* About Title CSS Start */
.about-title {
  height: 350px;
  background: #008ed2;
}
.about-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.about-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.about-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.about-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.about-title .title-text ul li a {
  color: #FFFFFF;
}
.about-title .title-text ul li a:hover {
  color: #008dd2;
}
.about-title .title-text ul li i {
  font-size: 14px;
}
.about-title .title-text ul li:last-child::before {
  display: none;
}

/* About Title CSS End */
/* Theory CSS Start */
.theory-style-two {
  margin-top: 75px;
  margin-bottom: -130px;
  z-index: 1;
  position: relative;
}
.theory-style-two .theory-area {
  box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.14);
}
.theory-style-two .theory-area .theory-img {
  bottom: 0;
}

/* Theory CSS End */
/* Video CSS Start */
.video-style-three {
  padding-top: 340px;
}

/* Video CSS End */
/**-------------- About Page CSS End --------------**/
/**-------------- Portfolio Page CSS Start --------------**/
.portfolio-title {
  height: 400px;
  background: #008ed2;
}
.portfolio-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.portfolio-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.portfolio-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.portfolio-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.portfolio-title .title-text ul li a {
  color: #FFFFFF;
}
.portfolio-title .title-text ul li a:hover {
  color: #008dd2;
}
.portfolio-title .title-text ul li i {
  font-size: 14px;
}
.portfolio-title .title-text ul li:last-child::before {
  display: none;
}

/**-------------- Portfolio Page CSS End --------------**/
/**-------------- Blog Page CSS Start --------------**/
.blog-title {
  height: 400px;
  background: #008ed2;
}
.blog-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.blog-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.blog-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.blog-title .title-text ul li a {
  color: #FFFFFF;
}
.blog-title .title-text ul li a:hover {
  color: #008dd2;
}
.blog-title .title-text ul li i {
  font-size: 14px;
}
.blog-title .title-text ul li:last-child::before {
  display: none;
}

/**-------------- Blog Page CSS End --------------**/
/**-------------- Blog Details Page CSS Start --------------**/
.blog-details-area .blog-description .article-image {
  margin-bottom: 35px;
  position: relative;
}
.blog-details-area .blog-description .article-image .blog-date {
  position: absolute;
  background: #008dd2;
  width: 55px;
  text-align: center;
  top: 25px;
  left: 25px;
  padding: 6px;
}
.blog-details-area .blog-description .article-image .blog-date span {
  color: #FFFFFF;
  display: inline-block;
  line-height: 25px;
}
.blog-details-area .blog-description .article-info {
  margin-bottom: 25px;
  position: relative;
}
.blog-details-area .blog-description .article-info ul {
  margin: 0;
  padding-left: 0;
}
.blog-details-area .blog-description .article-info ul li {
  font-size: 14px;
  color: #008dd2;
  display: inline-block;
  margin-right: 15px;
  font-weight: 700;
}
.blog-details-area .blog-description .article-info ul li i {
  margin-right: 5px;
  display: none;
}
.blog-details-area .blog-description .article-title {
  font-size: 32px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 25px;
}
.blog-details-area .blog-description p {
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}
.blog-details-area .blog-description .article-bottom-img {
  margin-bottom: 20px;
  margin-top: 10px;
}
.blog-details-area .blog-description .article-meta {
  position: relative;
  padding-top: 15px;
  margin-bottom: 30px;
}
.blog-details-area .blog-description .article-meta::before {
  position: absolute;
  content: "";
  background: #ebebeb;
  width: 50%;
  height: 1px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.blog-details-area .blog-description .article-meta ul {
  padding-left: 0;
  margin-bottom: 0;
}
.blog-details-area .blog-description .article-meta ul li {
  display: inline-block;
  font-size: 14px;
  margin-right: 15px;
  color: #808291;
}
.blog-details-area .blog-description .article-meta ul li i {
  margin-right: 5px;
}
.blog-details-area .blog-description .blog-nav {
  display: flex;
  margin-bottom: 40px;
  background: #ddd;
  padding: 20px;
  margin-top: 20px;
}
.blog-details-area .blog-description .blog-nav .prev-btn, .blog-details-area .blog-description .blog-nav .next-btn {
  width: 50%;
}
.blog-details-area .blog-description .blog-nav .prev-btn a, .blog-details-area .blog-description .blog-nav .next-btn a {
  color: #3f3f3f;
  border: 1px solid #008dd2;
  border-radius: 5px;
  padding: 8px 20px;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  background: #FFFFFF;
}
.blog-details-area .blog-description .blog-nav .prev-btn a:hover, .blog-details-area .blog-description .blog-nav .next-btn a:hover {
  background: #008dd2;
  color: #FFFFFF;
}
.blog-details-area .blog-description .blog-comment {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin-bottom: 30px;
}
.blog-details-area .blog-description .blog-comment h3 {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 25px;
}
.blog-details-area .blog-description .blog-comment .form-control {
  height: 60px;
  padding-left: 20px;
  border: 1px solid #008dd2;
  width: 100%;
  border-radius: 0;
  background: #fcf6f6;
  margin-bottom: 30px;
}
.blog-details-area .blog-description .blog-comment .message-field {
  padding-top: 20px;
  padding-left: 20px;
  border: 1px solid #008dd2;
  width: 100%;
  background: #fcf6f6;
}
.blog-details-area .blog-description .blog-comment .message-field:focus {
  outline: 0;
}
.blog-details-area .blog-description .blog-comment ::placeholder {
  font-size: 14px;
  color: #a07373;
  font-family: "Poppins", sans-serif;
}
.blog-details-area .blog-description .blog-comment .default-btn {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background: #008dd2;
  color: #FFFFFF;
  padding: 15px 40px;
  margin-top: 10px;
  border: 1px solid transparent;
  transition: 0.5s;
}
.blog-details-area .blog-description .blog-comment .default-btn:hover {
  background: #FFFFFF;
  border: 1px dashed #008dd2;
  color: #008dd2;
}
.blog-details-area .blog-search {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 30px;
}
.blog-details-area .blog-search form {
  position: relative;
}
.blog-details-area .blog-search form input {
  border: 1px solid #008dd2;
  padding: 10px 20px;
  width: 100%;
}
.blog-details-area .blog-search form input:focus {
  outline: 0;
}
.blog-details-area .blog-search form ::placeholder {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #333333;
}
.blog-details-area .blog-search form button {
  position: absolute;
  background: #008dd2;
  text-align: center;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  font-size: 18px;
  transition: 0.5S;
}
.blog-details-area .blog-search form button:hover {
  background: #333333;
}
.blog-details-area .blog-search form button i {
  color: #FFFFFF;
}
.blog-details-area .recent-blog {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}
.blog-details-area .recent-blog h3 {
  font-weight: 600;
  margin-bottom: 20px;
}
.blog-details-area .recent-blog .recent-post {
  position: relative;
  margin-bottom: 20px;
  border-bottom: 1px dashed #dddddd;
  padding-bottom: 20px;
}
.blog-details-area .recent-blog .recent-post img {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
.blog-details-area .recent-blog .recent-post h3 {
  margin-bottom: 0;
}
.blog-details-area .recent-blog .recent-post h3 a {
  color: #333333;
  font-size: 14px;
  padding-left: 110px;
  margin-bottom: 10px;
  padding-top: 8px;
  display: inline-block;
  line-height: 18px;
}
.blog-details-area .recent-blog .recent-post h3 a:hover {
  color: #008dd2;
}
.blog-details-area .recent-blog .recent-post ul {
  padding-left: 110px;
  margin-bottom: 0;
}
.blog-details-area .recent-blog .recent-post ul li {
  display: inline-block;
  color: #008dd2;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-right: 10px;
}
.blog-details-area .recent-blog .recent-post ul li i {
  font-size: 14p;
}
.blog-details-area .recent-blog .recent-post:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.blog-details-area .blog-category {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}
.blog-details-area .blog-category h3 {
  font-weight: 600;
  margin-bottom: 20px;
}
.blog-details-area .blog-category ul {
  padding-left: 0;
  margin-bottom: 0;
}
.blog-details-area .blog-category ul li {
  line-height: 1;
  list-style: none;
}
.blog-details-area .blog-category ul li a {
  padding: 15px 20px;
  display: block;
  border-left: 2px solid #008dd2;
  position: relative;
  border-bottom: 1px dashed #dddddd;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 5px;
}
.blog-details-area .blog-category ul li a i {
  position: absolute;
  top: 12px;
  right: 0px;
  font-size: 25px;
}
.blog-details-area .blog-category ul li a:hover {
  background: #008dd2;
  color: #FFFFFF;
}
.blog-details-area .photo-gallery {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}
.blog-details-area .photo-gallery h3 {
  font-weight: 600;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-top: 20px;
}
.blog-details-area .photo-gallery a img {
  padding-left: 20px;
  margin-bottom: 20px;
}
.blog-details-area .tags {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}
.blog-details-area .tags h3 {
  font-weight: 600;
  margin-bottom: 20px;
}
.blog-details-area .tags a {
  background: #e5e5e5;
  color: #333333;
  padding: 5px 15px;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 10px;
  border: 1px dashed #333333;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
}
.blog-details-area .tags a:hover {
  background: #008dd2;
  color: #FFFFFF;
}

/**-------------- Blog Details Page CSS End --------------**/
/**-------------- Contact Page CSS Start --------------**/
/* Contact Title Start */
.contact-title {
  background: #fff;
  padding-bottom: 60px;
}
.contact-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.contact-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.contact-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.contact-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.contact-title .title-text ul li a {
  color: #FFFFFF;
}
.contact-title .title-text ul li a:hover {
  color: #008dd2;
}
.contact-title .title-text ul li i {
  font-size: 14px;
}
.contact-title .title-text ul li:last-child::before {
  display: none;
}

/* Contact Title Start */
/* Contact Section Start */
.contact-area .contact-box {
  margin-top: -100px;
  background: #FFFFFF;
  padding: 60px 45px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.contact-area .contact-box .contact-card {
  height: 150px;
  border: 1px solid #008dd2;
  position: relative;
  padding: 20px;
}
.contact-area .contact-box .contact-card i {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #008dd2;
  display: inline-block;
  color: #008dd2;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  position: absolute;
  top: 37px;
  left: 25px;
}
.contact-area .contact-box .contact-card h3 {
  padding-left: 80px;
  font-weight: 700;
}
.contact-area .contact-box .contact-card ul {
  padding-left: 0;
  margin-bottom: 0;
  padding-left: 80px;
}
.contact-area .contact-box .contact-card ul li {
  color: #4a4a4a;
  font-size: 14px;
  list-style: none;
}

.map {
  line-height: 1;
  height: 500px;
  margin-bottom: -120px;
}
.map iframe {
  width: 100%;
  height: 500px;
  border: 0;
}

/* Contact Section End */
/* Footer Area Start */
.footer-style-three {
  clip-path: none;
}

/* Footer Area End */
/**-------------- Contact Page CSS End --------------**/
/**-------------- Service Page CSS Start --------------**/
.service-title {
  height: 400px;
  background: #008ed2;
}
.service-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.service-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.service-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.service-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.service-title .title-text ul li a {
  color: #FFFFFF;
}
.service-title .title-text ul li a:hover {
  color: #008dd2;
}
.service-title .title-text ul li i {
  font-size: 14px;
}
.service-title .title-text ul li:last-child::before {
  display: none;
}

.service-style-three .service-card {
  text-align: center;
  box-shadow: 0px 2px 20px 0px rgba(12, 0, 46, 0.1);
  padding: 30px 20px;
  margin-bottom: 30px;
  transition: 0.5s;
  position: relative;
  border-bottom: 2px solid #717477;
}
.service-style-three .service-card:hover {
  margin-top: -10px;
}
.service-style-three .service-card:hover i {
  animation: none;
  border: 1px dashed #008dd2;
  color: #008dd2;
}
.service-style-three .service-card i {
  font-size: 35px;
  width: 90px;
  height: 90px;
  display: inline-block;
  text-align: center;
  line-height: 90px;
  border-radius: 46% 54% 68% 32%/55% 48% 52% 45%;
  margin-bottom: 25px;
  animation: icon-animation 5s ease-in-out infinite;
  border: 1px dashed;
  transition: 0.5s;
}
.service-style-three .service-card h3 {
  font-weight: 600;
  margin-bottom: 10px;
}
.service-style-three .service-card p {
  margin-bottom: 0;
  font-size: 16px;
  color: #0f2137;
}

/**-------------- Serice Page CSS End --------------**/
/**-------------- Portfolio Page Two CSS Start --------------**/
.portfolio-style-two .portfolio-menu {
  margin-bottom: 50px;
}
.portfolio-style-two .portfolio-menu ul li {
  cursor: pointer;
  padding: 10px 20px;
  list-style-type: none;
  display: inline-block;
  border: 1px solid #008dd2;
  transition: 0.5s;
}
.portfolio-style-two .portfolio-menu ul li:hover {
  background: #008dd2;
  color: #FFFFFF;
}
.portfolio-style-two .portfolio-menu ul .mixitup-control-active {
  color: #FFFFFF;
  background: #008dd2;
}

/**-------------- Portfolio Page Two CSS End --------------**/
/**-------------- Pricing Page CSS Start --------------**/
.pricing-title {
  height: 400px;
  background: #008ed2;
}
.pricing-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.pricing-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.pricing-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.pricing-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.pricing-title .title-text ul li a {
  color: #FFFFFF;
}
.pricing-title .title-text ul li a:hover {
  color: #008dd2;
}
.pricing-title .title-text ul li i {
  font-size: 14px;
}
.pricing-title .title-text ul li:last-child::before {
  display: none;
}

/**-------------- Pricing Page CSS End --------------**/
/**-------------- 404 Page CSS Start --------------**/
.error-title {
  height: 400px;
  background: #008ed2;
}
.error-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.error-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.error-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.error-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.error-title .title-text ul li a {
  color: #FFFFFF;
}
.error-title .title-text ul li a:hover {
  color: #008dd2;
}
.error-title .title-text ul li i {
  font-size: 14px;
}
.error-title .title-text ul li:last-child::before {
  display: none;
}

.error-section .error-img {
  margin-bottom: 50px;
}
.error-section .error-img img {
  width: 100%;
}

/**-------------- 404 Page CSS End --------------**/
/**-------------- Team Page CSS Start --------------**/
.team-title {
  height: 400px;
  background: #008ed2;
}
.team-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.team-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.team-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.team-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.team-title .title-text ul li a {
  color: #FFFFFF;
}
.team-title .title-text ul li a:hover {
  color: #008dd2;
}
.team-title .title-text ul li i {
  font-size: 14px;
}
.team-title .title-text ul li:last-child::before {
  display: none;
}

.footer-style-two {
  padding-top: 100px;
  clip-path: none;
}

/**-------------- Team Page CSS End --------------**/
/**-------------- Team Page Two CSS Start --------------**/
.team-style-two .team-card {
  margin-bottom: 30px;
}
.team-style-two .team-card .team-img {
  position: relative;
}
.team-style-two .team-card .team-img:hover .team-text {
  background: rgba(0, 0, 0, 0.5);
  transform: scale(1);
}
.team-style-two .team-card .team-img img {
  width: 100%;
}
.team-style-two .team-card .team-text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
  transform: scale(0);
}
.team-style-two .team-card .team-text h3 {
  font-size: 25px;
  color: #FFFFFF;
}
.team-style-two .team-card .team-text:hover {
  background: rgba(0, 0, 0, 0.5);
}

/**-------------- Team Page Two CSS End --------------**/
/**-------------- Service Page Details CSS Start --------------**/
.service-style-four .service-details-area .service-details-img {
  margin-bottom: 40px;
}
.service-style-four .service-details-area .service-details-img img {
  width: 100%;
}
.service-style-four .service-details-area h3 {
  position: relative;
  padding-left: 0;
  margin-bottom: 15px;
  font-size: 30px;
}
.service-style-four .service-details-area p {
  margin-bottom: 15px;
}
.service-style-four .service-details-area .mb-35 {
  margin-bottom: 35px;
}
.service-style-four .service-details-area .service-btm-img {
  margin: 10px 0 20px;
}
.service-style-four .blog-search {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 30px;
}
.service-style-four .blog-search form {
  position: relative;
}
.service-style-four .blog-search form input {
  border: 1px solid #008dd2;
  padding: 10px 20px;
  width: 100%;
}
.service-style-four .blog-search form input:focus {
  outline: 0;
}
.service-style-four .blog-search form ::placeholder {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #333333;
}
.service-style-four .blog-search form button {
  position: absolute;
  background: #008dd2;
  text-align: center;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  font-size: 18px;
  transition: 0.5S;
}
.service-style-four .blog-search form button:hover {
  background: #333333;
}
.service-style-four .blog-search form button i {
  color: #FFFFFF;
}
.service-style-four .service-category {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}
.service-style-four .service-category h3 {
  font-weight: 600;
  margin-bottom: 20px;
}
.service-style-four .service-category ul {
  padding-left: 0;
  margin-bottom: 0;
}
.service-style-four .service-category ul li {
  line-height: 1;
  list-style: none;
}
.service-style-four .service-category ul li a {
  padding: 15px 20px;
  display: block;
  border-left: 2px solid #008dd2;
  position: relative;
  border-bottom: 1px dashed #dddddd;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 5px;
}
.service-style-four .service-category ul li a i {
  position: absolute;
  top: 12px;
  right: 0px;
  font-size: 25px;
}
.service-style-four .service-category ul li a:hover {
  background: #008dd2;
  color: #FFFFFF;
}

/**-------------- Service Page Details CSS End --------------**/
/**-------------- FAQ Page CSS Start --------------**/
.faq-title {
  height: 400px;
  background: #008ed2;
}
.faq-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.faq-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.faq-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.faq-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.faq-title .title-text ul li a {
  color: #FFFFFF;
}
.faq-title .title-text ul li a:hover {
  color: #008dd2;
}
.faq-title .title-text ul li i {
  font-size: 14px;
}
.faq-title .title-text ul li:last-child::before {
  display: none;
}

.faq-area h2 {
  margin-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin-bottom: 50px;
  font-size: 32px;
  font-weight: 600;
  border-bottom: 2px dashed #886a03;
}
.faq-area .topic {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.faq-area .topic h3 {
  color: #333333;
}
.faq-area .open {
  cursor: pointer;
  display: block;
  padding: 0px;
  height: 60px;
}
.faq-area .open:hover {
  opacity: 0.7;
}
.faq-area .expanded {
  background-color: #f5f5f5;
  transition: all 0.3s ease-in-out;
}
.faq-area .question {
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #526ee4;
  padding-left: 40px;
}
.faq-area .answer {
  font-size: 16px;
  line-height: 26px;
  display: none;
  margin-bottom: 30px;
  text-align: justify;
  padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.faq-area .faq-t {
  transform: rotate(-45deg);
  display: inline-block;
  position: relative;
  top: -55px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: transparent;
  border-left: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  transition: all 0.3s ease-in-out;
}
.faq-area .faq-o {
  top: -50px;
  transform: rotate(-224deg);
}

/**-------------- FAQ Page CSS End --------------**/
/**-------------- Privacy Page CSS Start --------------**/
.privacy-title {
  height: 400px;
  background: #008ed2;
}
.privacy-title .title-text h2 {
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}
.privacy-title .title-text ul {
  padding-left: 0;
  margin-bottom: 0;
}
.privacy-title .title-text ul li {
  font-size: 18px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  margin: 0 13px;
}
.privacy-title .title-text ul li::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  top: 15px;
  right: -15px;
  border-radius: 10px;
}
.privacy-title .title-text ul li a {
  color: #FFFFFF;
}
.privacy-title .title-text ul li a:hover {
  color: #008dd2;
}
.privacy-title .title-text ul li i {
  font-size: 14px;
}
.privacy-title .title-text ul li:last-child::before {
  display: none;
}

.privacy-section {
  padding-bottom: 60px;
}
.privacy-section .privacy-text {
  margin-bottom: 15px;
}
.privacy-section .privacy-text h3 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #0f2137;
  text-transform: capitalize;
  margin-bottom: 10px;
  display: inline-block;
}
.privacy-section .privacy-text p {
  margin-bottom: 0;
}
.privacy-section ul li {
  margin-top: 10px;
  list-style: circle;
  color: #111111;
}

/**-------------- Privacy Page CSS End --------------**/
/**-------------- Back to Top CSS Start --------------**/
/**-------------- Back to Top Page CSS End --------------**/
/**-------------- Pre Loader CSS Start --------------**/
.loader-content {
  background: #FFFFFF;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  text-align: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #6096fd;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/**-------------- Pre Loader CSS End --------------**/
/* Magnific Popup */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* Animation CSS */
@keyframes icon-animation {
  0% {
    border-radius: 46% 54% 68% 32%/55% 48% 52% 45%;
  }
  50% {
    border-radius: 66% 34% 41% 59%/51% 34% 66% 49%;
  }
  100% {
    border-radius: 46% 54% 68% 32%/55% 48% 52% 45%;
  }
}
@keyframes wrep {
  0% {
    border-radius: 55% 45% 38% 62%/52% 57% 43% 48%;
  }
  35% {
    border-radius: 67% 33% 39% 61%/48% 63% 37% 52%;
  }
  70% {
    border-radius: 40% 60% 62% 38%/32% 41% 59% 68%;
  }
  100% {
    border-radius: 55% 45% 38% 62%/52% 57% 43% 48%;
  }
}
@keyframes translatex {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  10% {
    transform: translateX(0px);
  }
}
@keyframes translatey {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  10% {
    transform: translateY(0px);
  }
}
/* play button animation */
@keyframes pulse {
  from {
    transform: scale(1);
    filter: opacity(0.9);
  }
  to {
    transform: scale(2);
    filter: opacity(0);
  }
}
.service-style-three {
  background: #eaedff;
}
.service-style-three .service-slider {
  position: relative;
}
.service-style-three .service-slider::before {
  position: absolute;
  content: "";
  background-position: center center;
  background-size: cover;
  width: 150px;
  height: 150px;
  left: -10px;
  top: -80px;
}
.service-style-three .service-slider .service-item {
  text-align: center;
  border-radius: 10px;
  padding: 35px;
  transition: 0.5s;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  background: #FFFFFF;
  border: 1px solid #c3ccff;
}
.service-style-three .service-slider .service-item::before {
  position: absolute;
  content: "";
  background: #031888;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
  transition: 1s;
}
.service-style-three .service-slider .service-item:hover {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.service-style-three .service-slider .service-item:hover::before {
  height: 100%;
}
.service-style-three .service-slider .service-item:hover::after {
  opacity: 1;
}
.service-style-three .service-slider .service-item:hover i, .service-style-three .service-slider .service-item:hover h3, .service-style-three .service-slider .service-item:hover p {
  color: #FFFFFF;
}
.service-style-three .service-slider .service-item:hover i::after {
  transform: scale(1);
}
.service-style-three .service-slider .service-item:hover .service-shape img {
  opacity: 1;
}
.service-style-three .service-slider .service-item:hover .theme-button .default-btn {
  background: #FFFFFF;
}
.service-style-three .service-slider .service-item i {
  color: #6096fd;
  font-size: 50px;
  display: inline-block;
  transition: 1s;
  position: relative;
  z-index: 0;
  margin-bottom: 20px;
}
.service-style-three .service-slider .service-item h3 {
  color: #111111;
  margin-bottom: 15px;
  line-height: 1;
  transition: 0.5s;
}
.service-style-three .service-slider .service-item p {
  transition: 0.5s;
  margin-bottom: 20px;
}
.service-style-three .service-slider .service-item .theme-button .default-btn {
  color: #6096fd;
  border: 1px solid #6096fd;
  margin-right: 0;
  padding: 10px 40px;
}
.service-style-three .service-slider .service-item .theme-button .default-btn:hover::before {
  display: none;
  color: #6096fd;
}
.service-style-three .service-slider .service-item .service-shape img {
  position: absolute;
  opacity: 0;
  transition: 1s;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(1) {
  top: -30px;
  left: 20px;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(2) {
  top: 0;
  right: 0;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(3) {
  bottom: 0;
  right: 0;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(4) {
  bottom: 90px;
  left: 30px;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(5) {
  bottom: 0;
  left: 0;
}

.service-slider.owl-carousel .owl-item img {
  max-width: 100%;
  width: inherit;
}

.service-slider.owl-carousel .owl-nav button.owl-next,
.service-slider.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  color: #031888;
  width: 55px;
  height: 55px;
  background: #FFFFFF;
  border-radius: 50px;
  font-size: 30px;
  left: -95px;
  top: 40%;
  transition: 0.5s;
}
.service-slider.owl-carousel .owl-nav button.owl-next:hover,
.service-slider.owl-carousel .owl-nav button.owl-prev:hover {
  background: #031888;
  color: #FFFFFF;
}

.service-slider.owl-carousel .owl-nav button.owl-next {
  left: inherit;
  right: -95px;
}

/* Service CSS End */
.why-choose-accordian {
  margin-top: 30px;
  margin-right: 60px;
}
.why-choose-accordian .accordion {
  margin-bottom: 35px;
}
.why-choose-accordian .accordion .card {
  border-radius: 5px;
  margin-bottom: 15px;
  border: 0;
}
.why-choose-accordian .accordion .card .card-header {
  background: #FFFFFF;
  border-bottom: 0;
  padding: 0;
  margin: 0;
}
.why-choose-accordian .accordion .card .card-header h2 a {
  font-size: 16px;
  color: #111111;
  font-weight: 500;
  position: relative;
  display: block;
  text-align: left;
  padding: 15px 20px;
  line-height: 1;
}
.why-choose-accordian .accordion .card .card-body {
  color: #828282;
  font-size: 14px;
  padding: 10px 0 0 0;
  border-top: 1px solid #bebebe;
  margin: 0px 20px 20px 20px;
}
.why-choose-accordian .why-choose-contact .form-control {
  font-size: 16px;
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.35);
  padding: 12px 20px;
  border-radius: 5px;
  margin-right: 20px;
  float: left;
  margin-bottom: 15px;
  width: 278px;
  border: 0;
  height: 50px;
}
.why-choose-accordian .why-choose-contact ::placeholder {
  color: #FFFFFF;
}
.why-choose-accordian .why-choose-contact button {
  font-size: 16px;
  color: #FFFFFF;
  background: #6096fd;
  padding: 12px 35px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  height: 50px;
}
.why-choose-accordian .why-choose-contact button:hover {
  background: #031888;
  border: 1px solid #6096fd;
}
.why-choose-accordian .why-choose-contact p {
  font-size: 16px;
  color: #FFFFFF;
  margin-bottom: -8px;
}

.why-choose-accordian .accordion .card-header a::after {
  position: absolute;
  content: "x";
  font-size: 20px;
  color: #616161;
  font-weight: 500;
  right: 20px;
  top: 11px;
}

.why-choose-accordian .accordion a.collapsed::after {
  position: absolute;
  content: "+";
  font-size: 25px;
  color: #616161;
  font-weight: 500;
  right: 20px;
  top: 11px;
}

/* Why Choose CSS End */
.signature-box p {
  font-size: 12px;
  padding: 5px;
  color: #008ed2 !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

.contentOne {
  padding-top: 30px;
}
.contentOne h3 {
  font-weight: bold;
  font-size: 24px;
}
.contentOne p {
  font-size: 14px;
}

.sticky-footer .btn .icon-chat {
  top: 4px;
  font-size: 1.3125rem;
}

.sticky-footer__full .btn {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.sticky-side {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -17.4375rem;
  z-index: 100;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

@media (min-width: 992px) {
  .sticky-side {
    right: -18.4375rem;
  }
}
@media (min-width: 1200px) {
  .sticky-side {
    right: -17.675rem;
  }
}
@media (max-width: 767.98px) {
  .sticky-side {
    display: none;
  }
}
.sticky-side.open {
  right: 0;
}

.sticky-side__content {
  background-color: #000;
  max-width: 20.9875rem;
}

@media (min-width: 992px) {
  .sticky-side__content {
    max-width: 22.9875rem;
  }
}
.sticky-side__content .row {
  margin: 0;
}

.sticky-side__content .close-btn {
  text-align: right;
  position: absolute;
  right: 0.3125rem;
  top: -2.4375rem;
}

@media (min-width: 992px) {
  .sticky-side__content .close-btn {
    top: -2.9375rem;
  }
}
.sticky-side__content .close-btn button {
  background-color: rgba(0, 0, 0, 0.9);
  color: #b8b8c0;
  border: 0;
  border-radius: 100%;
  outline: none;
  font-size: 0.625rem;
  line-height: 1;
  padding: 0.6rem 0.5rem 0.4rem;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.sticky-side__content .close-btn button:hover {
  background-color: #008ed2;
  color: #000;
}

.sticky-side__content .text-wrap {
  color: #fff;
}

.sticky-side__content .text-wrap h5 {
  color: #fff;
  font-size: 1.5625rem;
  font-weight: 600;
  margin-bottom: 0.8125rem;
}

@media (min-width: 992px) {
  .sticky-side__content .text-wrap h5 {
    margin-bottom: 1.4rem;
  }
}
.sticky-side__content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.25rem;
}

.sticky-side__content ul li {
  padding-left: 2.375rem;
  position: relative;
  margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .sticky-side__content ul li {
    margin-bottom: 0.8125rem;
  }
}
.sticky-side__content ul li:last-child {
  margin-bottom: 0;
}

.sticky-side__content ul li span {
  color: #008ed2;
  position: absolute;
  left: 0.125rem;
  top: 0.3125rem;
}

.sticky-side__content ul li span.icon-phone-alt {
  font-size: 1.0625rem;
}

.sticky-side__content ul li span.icon-chat {
  font-size: 1.3125rem;
}

.sticky-side__content ul li span.icon-calender {
  font-size: 1.0625rem;
  top: 0.4375rem;
  left: 0.1875rem;
}

.sticky-side__content ul li a {
  color: #fff;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  text-decoration: none;
}

.sticky-side__content ul li a:hover {
  color: #008ed2;
}

.sticky-side__help {
  cursor: pointer;
}

.sticky-side__help:after {
  top: -3rem;
}

.sticky-side__btn {
  max-width: 17%;
  -ms-flex-preferred-size: 17%;
  flex-basis: 17%;
  background-color: #008ed2;
  position: relative;
}

@media (min-width: 992px) {
  .sticky-side__btn {
    max-width: 20%;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1200px) {
  .sticky-side__btn {
    max-width: 23%;
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
  }
}
.sticky-side__btn .img-wrap {
  border-radius: 100%;
  overflow: hidden;
  border: 3px solid #008ed2;
  margin-top: -1.9375rem;
  z-index: 1;
  position: relative;
  width: 57px;
  height: 57px;
  background-color: #008ed2;
}

@media (min-width: 992px) {
  .sticky-side__btn .img-wrap {
    margin-top: -2.625rem;
    width: 74px;
    height: 74px;
  }
}
@media (min-width: 1200px) {
  .sticky-side__btn .img-wrap {
    margin-top: -2.9rem;
  }
}
@media (min-width: 1850px) {
  .sticky-side__btn .img-wrap {
    width: 5.3125rem;
    height: 5.3125rem;
  }
}
.sticky-side__btn .img-wrap figure {
  margin-bottom: 0;
}

.sticky-side__btn .img-wrap img {
  width: 100%;
}

.sticky-side__btn .btn-wrap {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  margin-top: 4.5625rem;
}

@media (min-width: 992px) {
  .sticky-side__btn .btn-wrap {
    margin-top: 4.0625rem;
  }
}
@media (min-width: 1200px) {
  .sticky-side__btn .btn-wrap {
    margin-top: 2.6rem;
  }
}
.sticky-side__btn button {
  line-height: 1;
  padding: 0.8375rem 0.75rem;
  background-color: #008ed2;
  border: 0;
  font-weight: 700;
  font-size: 1.8125rem;
  white-space: nowrap;
  outline: none;
}

@media (min-width: 992px) {
  .sticky-side__btn button {
    font-size: 2rem;
    padding: 1.25rem 1.3rem;
  }
}
@media (min-width: 1200px) {
  .sticky-side__btn button {
    padding: 1.5625rem 1.4rem;
  }
}
.sticky-side__info {
  padding: 1.9375rem;
  position: relative;
  -ms-flex-preferred-size: 83%;
  flex-basis: 83%;
  max-width: 83%;
  border: 2px solid #008ed2;
  border-left: 0;
}

@media (min-width: 992px) {
  .sticky-side__info {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
    max-width: 80%;
    padding: 2.375rem 1.9375rem;
  }
}
@media (min-width: 1200px) {
  .sticky-side__info {
    -ms-flex-preferred-size: 77%;
    flex-basis: 77%;
    max-width: 77%;
  }
}